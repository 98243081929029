:root { 
  // PRIMARY COLOR
--primary-50: ;
--primary-100: ;
--primary-200: ;
--primary-300: ;
--primary-400: ;
--primary-500: 239, 39, 119; //ef3777
--primary-600: 229, 40, 105; // #e52869
--primary-700: 190, 24, 93; // be185d
--primary-800: ;
--primary-900: ;

  // SECONDARY COLOR
--secondary-50: ;
--secondary-100: ;
--secondary-200: 245, 247, 251; // #F5F7FB
--secondary-300: ;
--secondary-400: ;
--secondary-500:99, 102, 241;
--secondary-600:79, 70, 229; // #4f46e5
--secondary-700: 107, 33, 168;// #6B21A8 // 67, 56, 202;
--secondary-800: 7, 7, 77;  // #07074D
--secondary-900: 26, 28, 56; // #1a1c38

// TERTIARY COLOR
--tertiary-50: ;
--tertiary-100: ;
--tertiary-200: ;
--tertiary-300: ;
--tertiary-400: ;
--tertiary-500: 254, 240, 138;// 233, 233, 208; vert
--tertiary-600: 253, 237, 117;
--tertiary-700: ;
--tertiary-800: ;
--tertiary-900: ;

// INFO COLOR
--info-50: 236, 254, 255 ;// ecfeff
--info-100: 207, 250, 254;  // cffafe
--info-200: 186, 230, 253;
--info-300: 125, 211, 252;
--info-400: 56, 189, 248;
--info-500: 59, 130, 246;
--info-600: 36,114, 241;
--info-700: 3, 105, 161;
--info-800: 7, 89, 133;
--info-900: 12, 74, 110;

// SUCCESS COLOR
--success-50: 240, 253, 244;
--success-100: 220, 252, 231;
--success-200: 187, 247, 208;
--success-300: 134, 239, 172;
--success-400: 74, 222, 128;
--success-500: 34, 197, 94;
--success-600: 30, 175, 83;
--success-700: 21, 128, 61;
--success-800: 22, 101, 52;
--success-900: 20, 83, 45;

// WARNING COLOR
--warning-50: ;
--warning-100: ;
--warning-200: ;
--warning-300: 254, 240, 138; //fef08a
--warning-400: ;
--warning-500: 241, 196, 15; // f1c40f
--warning-600: 235, 189, 5; //ebbd05
--warning-700: ;
--warning-800: ;
--warning-900: ;

// DANGER COLOR
--danger-50: ;
--danger-100: ;
--danger-200: ;
--danger-300: ;
--danger-400: ;
--danger-500: 220, 38, 38;
--danger-600: 203, 35, 35;
--danger-700: ;
--danger-800: ;
--danger-900: ;
/*
// ERROR COLOR a supprimer
--error-50: ;
--error-100: ;
--error-200: ;
--error-300: ;
--error-400: ;
--error-500: 231, 38, 120; // #ef2777
--error-600: ;
--error-700: ;
--error-800: ;
--error-900: ;
*/
  // NEUTRAL COLOR
--neutral-50: 249, 250, 251;
//--neutral-100: 243, 244, 246;
--neutral-100: 250, 251, 255;
//--neutral-200: 229, 231, 235;
--neutral-200: 231, 236, 243;
--neutral-300: 209, 213, 219;
--neutral-400: 156, 163, 175;
--neutral-500: 107, 114, 128;
--neutral-600: 75, 85, 99;
--neutral-700: 55, 65, 81;
--neutral-800: 31, 41, 55;
--neutral-900: 17, 24, 39;
}
