.root {
  overflow: hidden;
}
.loading {
  color: #1ABC9C;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img,
svg {
  display: inline;
}

a,
button {
  cursor: pointer;
  text-decoration:inherit;
}

.pageContainer {
  opacity: 0;
  transition: 1s;
}

.fadeIn {
  opacity: 1;
}


.sticky {
  position: sticky;
  position: -webkit-sticky;
}

/*** DOCUMENTATION ******/

.documentation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 60%;
    margin: 0 auto;
    height: auto;
  }

  h1 {
    text-align: center;
    font-size: 2.5rem;

  }

  h2 {
    text-align: center;
    font-size: 2rem;

  }

  h3 {
    text-align: center;
    font-size: 1.5rem;

  }

  h4 {
    text-align: center;
    font-size: 1.25rem;

  }

  p {
    text-align: justify;
    text-align-last: center;
    font-size: 1rem;
    margin: 0 auto;
  }

  ul {
    font-size: 1rem;

    li {
      list-style: inside;
    }
  }

  ol {
    list-style: none;
    counter-reset: counter;
  }

  ol li {
    counter-increment: counter;
  }

  ol li::before {
    content: counter(counter) ". ";
  }


}

