.LoaderDots div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.LoaderDots div:nth-child(1) {
  left: 8px;
  animation: LoaderDots1 0.6s infinite;
}
.LoaderDots div:nth-child(2) {
  left: 8px;
  animation: LoaderDots2 0.6s infinite;
}
.LoaderDots div:nth-child(3) {
  left: 32px;
  animation: LoaderDots2 0.6s infinite;
}
.LoaderDots div:nth-child(4) {
  left: 56px;
  animation: LoaderDots3 0.6s infinite;
}
@keyframes LoaderDots1 {
  0% {
      transform: scale(0);
  }
  100% {
      transform: scale(1);
  }
}
@keyframes LoaderDots3 {
  0% {
      transform: scale(1);
  }
  100% {
      transform: scale(0);
  }
}
@keyframes LoaderDots2 {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(24px, 0);
  }
}
